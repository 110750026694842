exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-audience-page-js": () => import("./../../../src/pages/AudiencePage.js" /* webpackChunkName: "component---src-pages-audience-page-js" */),
  "component---src-pages-dashboard-page-js": () => import("./../../../src/pages/DashboardPage.js" /* webpackChunkName: "component---src-pages-dashboard-page-js" */),
  "component---src-pages-history-page-js": () => import("./../../../src/pages/HistoryPage.js" /* webpackChunkName: "component---src-pages-history-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-page-js": () => import("./../../../src/pages/LoginPage.js" /* webpackChunkName: "component---src-pages-login-page-js" */),
  "component---src-pages-pagination-js": () => import("./../../../src/pages/Pagination.js" /* webpackChunkName: "component---src-pages-pagination-js" */),
  "component---src-pages-settings-page-js": () => import("./../../../src/pages/SettingsPage.js" /* webpackChunkName: "component---src-pages-settings-page-js" */),
  "component---src-pages-text-analysis-page-js": () => import("./../../../src/pages/TextAnalysisPage.js" /* webpackChunkName: "component---src-pages-text-analysis-page-js" */)
}

