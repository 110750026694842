import React, { createContext, useState, useEffect } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    // Initialize state without directly accessing localStorage
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [website, setWebsite] = useState('');

    useEffect(() => {
        // Check if running in a browser environment
        if (typeof window !== "undefined") {
            const storedIsAuthenticated = localStorage.getItem("isAuthenticated") === "true";
            const storedWebsite = localStorage.getItem("website") || '';

            // Update state based on localStorage, only runs on client side
            setIsAuthenticated(storedIsAuthenticated);
            setWebsite(storedWebsite);
        }
    }, []);

    useEffect(() => {
        // Also check if running in a browser before accessing localStorage
        if (typeof window !== "undefined") {
            localStorage.setItem("isAuthenticated", isAuthenticated.toString());
            localStorage.setItem("website", website);
        }
    }, [isAuthenticated, website]);

    const logout = () => {
        setIsAuthenticated(false);
        setWebsite('');
        // Ensure localStorage manipulation only occurs client-side
        if (typeof window !== "undefined") {
            localStorage.setItem("isAuthenticated", "false");
            localStorage.removeItem("website");
        }
    };

    const updateLoginState = (website) => {
        setIsAuthenticated(true);
        setWebsite(website);
        // Ensure localStorage manipulation only occurs client-side
        if (typeof window !== "undefined") {
            localStorage.setItem("isAuthenticated", "true");
            console.log('ITEM', website);
            localStorage.setItem("website", website);
        }
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, website, login: updateLoginState, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
